import { Action } from '@ngrx/store';
import { Conversation } from 'src/app/shared/types/conversation.types';
import { User } from '../../shared/types/user.types';

export const UPDATE_USER = '[SETTINGS] Update user';
export const UPDATE_CONVERSATIONS = '[SETTINGS] Update conversations';
export const REMOVE_USER = '[SETTINGS] Remove user';

export class UpdateUser implements Action {
  readonly type = UPDATE_USER;
  constructor(public user: User) {}
}

export class UpdateConversations implements Action {
  readonly type = UPDATE_CONVERSATIONS;
  constructor(public conversations: Conversation[]) {}
}

export class RemoveUser implements Action {
  readonly type = REMOVE_USER;
  constructor() {}
}

export type Actions = UpdateUser | RemoveUser | UpdateConversations;

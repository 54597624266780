import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UpdateUser } from '../../core/actions/settings.actions';
import { AppState } from '../../core/reducers';
import { User } from '../../shared/types/user.types';
import { Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';

@Injectable()
export class AuthenticationService {
  constructor(private http: HttpClient, private store: Store<AppState>, private router: Router) {}

  loginUrl = environment.apiUrl + '/auth/jwt/create/';

  /**
   * Lets the user log with standard credentials to BE
   * @param username login credential to project's BE
   * @param password login credential to project's BE
   * @returns True if user logged in successfuly or False otherwise
   */
  public async signIn(username: string, password: string): Promise<User> {
    try {
      const signInResult = await lastValueFrom(
        this.http.post<{ access: string; refresh: string }>(this.loginUrl, {
          username,
          password,
        })
      );
      if (signInResult && signInResult.access) {
        const now = new Date();
        const tokenExpiration = new Date(now.getTime() + 24 * 60 * 60 * 1000);
        localStorage.setItem('tokenExpiration', tokenExpiration.toString());
        localStorage.setItem('access_token', signInResult['access']);
        localStorage.setItem('refresh_token', signInResult['refresh']);
        const user = await this.getMe();
        this.store.dispatch(new UpdateUser(user));
        return user;
      } else {
        return null;
      }
    } catch (err) {
      return null;
    }
  }

  isTokenExpired(): boolean {
    const expiration = localStorage.getItem('tokenExpiration');
    if (!expiration) {
      return true;
    }
    const expirationDate = new Date(expiration);
    return expirationDate <= new Date();
  }

  public async refreshToken() {
    if (this.isTokenExpired()) {
      const refreshToken = localStorage.getItem('refresh_token');
      if (!refreshToken) {
        return null;
      }
      try {
        this.http.post<any>(`${environment.apiUrl}/auth/jwt/refresh/`, { refresh: refreshToken }).subscribe({
          next: results => {
            if (results && results.access) {
              const now = new Date();
              const tokenExpiration = new Date(now.getTime() + 24 * 60 * 60 * 1000);
              localStorage.setItem('tokenExpiration', tokenExpiration.toString());
              localStorage.setItem('access_token', results.access);
              return results.access;
            }
          },
          error: (err: any) => {
            console.error('Invalid response from token refresh: ', err.error);
            this.signOut();
            this.router.navigate([`/auth/`]);
          },
          complete: () => {},
        });
      } catch (error) {
        // Handle error from token refresh request
        console.error('Error refreshing token: ', error);
        return null;
      }
    }
    return localStorage.getItem('access_token');
  }

  public getToken(): string {
    return localStorage.getItem('access_token');
  }

  /**
   * Removes user's traces
   */
  signOut(): void {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('tokenExpiration');
    this.store.dispatch(new UpdateUser(null));
  }

  /**
   * Checks whether the token in local storage is valid
   * @param token JWT token to be tested against project's BE
   * @returns True if token is valid, False otherwise
   */
  private async verifyToken(token: string): Promise<boolean> {
    try {
      const res = await this.http.post<any>(environment.apiUrl + '/auth/jwt/verify/', { token: token }).toPromise();
      return !!res;
    } catch (err) {
      return false;
    }
  }

  /**
   * Gets user from backend by token in localstorage
   * @returns user if token is valid null otherwise
   */
  public async getMe(): Promise<User> {
    if (localStorage.getItem('access_token') === null) {
      return null;
    }
    try {
      const res = await this.http.get<User>(environment.apiUrl + '/auth/users/me/').toPromise();
      const user: User = {
        username: res.username,
        id: res.id,
        role: res.role,
        email: res.email,
      };
      return { ...user };
    } catch (err) {
      this.signOut();
      this.router.navigate([`/auth/`]);
      return null;
    }
  }

  public resetPassword(email: string) {
    return this.http.post<HttpResponse<any>>(environment.apiUrl + '/auth/users/reset_password/', { email, role: 'AD' });
  }

  public resetPasswordConfirm(uid: string, token: string, new_password: string) {
    return this.http.post<HttpResponse<any>>(
      environment.apiUrl + '/auth/users/reset_password_confirm/',
      { uid, token, new_password },
      { observe: 'response' }
    );
  }

  public setPassword(current_password, new_password) {
    return this.http.post<HttpResponse<any>>(environment.apiUrl + '/auth/users/set_password/', {
      current_password,
      new_password,
    });
  }
}
